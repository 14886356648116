import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/nav-logo-bl.png';
import Topbar from '../../components/topbar/Topbar';
import './NavbarStyles.css';
import ContactForm from '../../components/contact/ContactFormModal';  // Import the ContactForm

// Modal component for Contact Form
const ContactFormModal = ({ showModal, closeModal }) => {
    return (
        <>
            {showModal && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        {/* Contact Form */}
                        <ContactForm />
                        <button className="close-modal" onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();  // Get the current route

    // Handle navigation menu toggle
    const handleNav = () => setNav(!nav);

    // Close modal function
    const closeModal = () => setShowModal(false);

    // Show the modal when user visits '/tms-therapy'
    useEffect(() => {
        if (location.pathname === '/tms-therapy') {
            setShowModal(true);
        }
    }, [location]);

    return (
        <>
            <Topbar />

            <div className='navbar'>
                <div className='nav-box'>
                    <div className="navbar-logo">
                        <Link to='/'>
                            <img src={logo} alt="Kings TMS" loading="lazy" />
                        </Link>
                    </div>

                    <ul className={nav ? 'nav-menu active' : 'nav-menu'}>
                        <li><Link to='/' onClick={() => setNav(false)}>Home</Link></li>
                        <li><Link to='/tms-therapy' onClick={() => setNav(false)}>TMS Therapy</Link></li>
                        <li><Link to='/about-us' onClick={() => setNav(false)}>About Us</Link></li>
                        <li><Link to='/insurance' onClick={() => setNav(false)}>Insurance</Link></li>
                        <li><Link to='/faq' onClick={() => setNav(false)}>FAQs</Link></li>
                        <li><Link to='/contact' onClick={() => setNav(false)}>Contact Us</Link></li>
                    </ul>

                    <div className="hamburger" onClick={handleNav}>
                        {!nav ? (<FaBars className='icon' />) : (<FaTimes className='icon' />)}
                    </div>
                </div>
            </div>

            {/* Modal with Contact Form */}
            <ContactFormModal showModal={showModal} closeModal={closeModal} />
        </>
    );
};

export default Navbar;
