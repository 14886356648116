import React from 'react'
import Fade from 'react-reveal/Fade';
import './NasalEsketamineSection.css'



const NasalEsketamineSection = () => {
  return (
    <>
<div className='nasalEsketamineServices'>

<Fade right>
        <div className="nasalEsketamineServices-content">
        <h1>Comming Soon!</h1>
        </div>
</Fade>
</div>

    </>
  )
}

export default NasalEsketamineSection




