import React, { useRef, useState } from "react";
import './ContactForm.css';
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";


const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");

    const sendEmail = (e) => {
        e.preventDefault();

        // Send email using emailjs
        emailjs.sendForm("service_bdfl0ie", "template_2mmhvak", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setButtonText("Message Sent"); // Change button text upon successful submission
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset(); // Reset form after submission
    };

    return (
        <div className="contactform container">

            <div className="contact-container">
                <div className="contact-form-content container">
                    <div className="right-side">

                        <div className="topic-text">
                            <h1>SEND US A MESSAGE</h1>
                            <i><p>We'll keep your contact information strictly confidential.</p></i>
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="input-box">
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder="Your Name" 
                                    required 
                                    pattern="[A-Za-z\s]+" 
                                    title="Please enter a valid name (letters and spaces only)" 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Your Email" 
                                    required 
                                    title="Please enter a valid email address" 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="tel" 
                                    name="phone" 
                                    placeholder="Your Phone" 
                                    required 
                                    pattern="\+?[0-9\s\-\(\)]+" 
                                    title="Please enter a valid phone number (digits, spaces, and symbols like +, -, () are allowed)" 
                                />
                            </div>
                            <div className="input-box">
                                <input 
                                    type="text" 
                                    name="insurance" 
                                    placeholder="Type of insurance" 
                                    required 
                                    pattern="[A-Za-z\s]+" 
                                    title="Please enter a valid insurance name (letters and spaces only)" 
                                />
                            </div>
                            <div className="input-box dob">
                                <label htmlFor="dob"><p>Date of Birth</p></label>
                                <input 
                                    type="date" 
                                    name="dob" 
                                    id="dob"
                                    required 
                                    title="Please select your date of birth"
                                />

                            </div>
                            <br/>

                            <div className="radio-button">
                            <p>Have you been diagnosed with chronic depression? 
                                    <br/>
                                    <div className="radioBtn">
                                    <input className="radio-ans" type="radio" id="yep" name="ans" value="Yes" required />
                                    <label htmlFor="yep">&nbsp;Yes</label>
                                    </div>
                                    <div className="radioBtn">
                                    <input className="radio-ans" type="radio" id="nope" name="ans" value="No" required />
                                    <label htmlFor="nope">&nbsp;No</label>
                                    </div>
                                </p>

                            </div>
                            <div className="input-box">
                                <input 
                                    type="text" 
                                    name="subject" 
                                    placeholder="Subject" 
                                    required 
                                    pattern="[A-Za-z0-9\s\-,.!?]+" 
                                    title="Please enter a valid message (letters, numbers, spaces, and punctuation are allowed)"
                                />
                            </div>
                            <div className="input-box message-box">
                                <textarea 
                                    name="message" 
                                    rows="4" 
                                    placeholder="How can we help?" 
                                    required 
                                    pattern="[A-Za-z0-9\s\-,.!?]+" 
                                    title="Please enter a valid message (letters, numbers, spaces, and punctuation are allowed)"
                                ></textarea>
                            </div>

                            <div className="disclaimer">
                              <p>Kings TMS needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our  
                              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                                  <Link to="/privacy-policy"><span> Privacy Policy </span></Link>
                              </LinkRoll></p>
                            </div>

                            <div className="contactFormBtn">
                            <input className="contact-btn" type="submit" value={buttonText} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default ContactForm;
