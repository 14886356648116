import React from 'react'

import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import NasalEsketamine from '../components/nasal-esketamine/NasalEsketamine'
import NasalEsketamineSection from '../components/nasal-esketamine/NasalEsketamineSection'


const Esketamine = () => {
  return (
    <>
    <Navbar /> 
    <NasalEsketamine />
    <NasalEsketamineSection />
    <Footer />
     </>
  )
}

export default Esketamine