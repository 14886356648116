import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import './TMS-Therapy.css';

// Modal component for contact form
const Modal = ({ showModal, closeModal }) => {
    return (
        <>
            {showModal && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Contact Us</h2>
                        <form className="contact-form">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" required />
                            
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" required />
                            
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message" required></textarea>
                            
                            <button type="submit">Submit</button>
                        </form>
                        <button className="close-modal" onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

const TMSTherapy = () => {
    const [showModal, setShowModal] = useState(false);

    // Open modal on page click
    const handlePageClick = () => {
        setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className='tms-therapy submenu' onClick={handlePageClick}>
                <div className="tms-therapy-content">
                    <Fade top>
                        <header>
                            <h1>Transcranial Magnetic Stimulation</h1>
                        </header>
                    </Fade>
                </div>
            </div>

            {/* Modal Component */}
            <Modal showModal={showModal} closeModal={closeModal} />
        </>
    );
};

export default TMSTherapy;
