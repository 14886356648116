import React from 'react'
import InsuranceSection from '../insurance/InsuranceSection';
import ContactForm from '../contact/ContactForm';
import Section2 from '../section2/Section2';
import Section2B from '../section2/Section2B';
import Testimonials from '../testimonials/Testimonials';


const home = () => {
  return (
    <>

    <Section2 />
    <Section2B />
    <Testimonials />
    <InsuranceSection />
    <ContactForm />
    </>
  )
}

export default home
