import React from 'react'
import './Testimonials.css'
import { FaQuoteLeft } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const Testimonials = () => {
  return (


    <div class="testimonials text-center">

    <div>
      <h3>What Our Patients Are Saying</h3>
      <div className='five-stars'>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />

            </div>

      <div class="row container">

        <div class="col-md-6 col-lg-6">
          <div class="card border-light bg-light text-center">
            <div className='quote'>
                <p><FaQuoteLeft /></p>
            </div>

            
            <div class="card-body blockquote">
              <p class="card-text">The way I feel now that I've completed the whole treatment is I feel way less stressed. I feel alive. I honestly look forward to waking up and seeing what the day has to offer, good or bad. I smoke way less and I'm more confident in myself. I don't have a dark cloud hanging over me anymore. The advice I would give somebody coming into treatment is, number one, stick with it. It happens a lot sooner than you think, and it honestly really works. The staff are amazing.<br/><br/> Recommendation 10/10.</p><br/><br/>
              <footer class="blockquote-footer"><cite title="Source Title">Brian (Kings TMS Patient)</cite></footer>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <div class="card border-light bg-light text-center">
          <div className='quote'>
                <p><FaQuoteLeft /></p>
            </div>
            <div class="card-body blockquote">
              <p class="card-text">I've suffered from anxiety and depression most of my life and had bad experiences with anti-depressants. I was concerned that I would have worse symptoms after I finished TMS. I also was worried the treatment wouldn't work. Treatments went smoothly. First I wanted to reach 120, but I learned to listen to my body and go as high as I was comfortable with. The techs always listened to my concerns. I was able to set appointments with the Dr. for questions. I have more energy now, also I haven’t had any depression episodes. I tell people all the time to give it a try. TMS has helped me in many ways. We are all here to "get better" and TMS is worth the time commitment</p> <br/>
              <footer class="blockquote-footer"><cite title="Source Title">Jordan (Kings TMS Patient)</cite></footer>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>



  )
}

export default Testimonials